import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:py-2 py-10">
                <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Video%2FKitchen%20Remodeling.mp4?alt=media&token=da405d00-323e-48c0-9ec0-0daed8dc7442"
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[50px] md:bottom-[100px] lg:bottom-[80px] md:text-start text-center text-white">
                    <div className="md:w-[60%] p-4">
                        <h1 className="md:text-start">{rpdata?.dbSlogan?.[3].slogan}</h1>
                        <p className="md:text-start">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;